import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-tanoariacoelho-adrego-com/src/components/layout.js";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
import GallerySection3 from '../components/sections/gallerySection3.js';
import GallerySection4 from '../components/sections/gallerySection4.js';
import GallerySection5 from '../components/sections/gallerySection5.js';
import GallerySection6 from '../components/sections/gallerySection6.js';
import GallerySection7 from '../components/sections/gallerySection7.js';
import GallerySection8 from '../components/sections/gallerySection8.js';
import GallerySection9 from '../components/sections/gallerySection9.js';
import GallerySection10 from '../components/sections/gallerySection10.js';
import GallerySection11 from '../components/sections/gallerySection11.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title={useIntl().formatMessage({
      id: "final-product.title"
    })} description={useIntl().formatMessage({
      id: "description"
    })} image={'https://www.tanoariacoelho-adrego.com' + ogImage} siteUrl={'https://www.tanoariacoelho-adrego.com' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1><FormattedMessage id="final-product.title" mdxType="FormattedMessage" /></h1>
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery3" mdxType="FormattedMessage" /></strong></p>
      <GallerySection3 mdxType="GallerySection3" />
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery4" mdxType="FormattedMessage" /></strong></p>
      <GallerySection4 mdxType="GallerySection4" />
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery5" mdxType="FormattedMessage" /></strong></p>
      <GallerySection5 mdxType="GallerySection5" />
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery6" mdxType="FormattedMessage" /></strong></p>
      <GallerySection6 mdxType="GallerySection6" />
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery7" mdxType="FormattedMessage" /></strong></p>
      <GallerySection7 mdxType="GallerySection7" />
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery8" mdxType="FormattedMessage" /></strong></p>
      <GallerySection8 mdxType="GallerySection8" />
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery9" mdxType="FormattedMessage" /></strong></p>
      <GallerySection9 mdxType="GallerySection9" />
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery10" mdxType="FormattedMessage" /></strong></p>
      <GallerySection10 mdxType="GallerySection10" />
      <p><strong parentName="p"><FormattedMessage id="final-product.gallery11" mdxType="FormattedMessage" /></strong></p>
      <GallerySection11 mdxType="GallerySection11" />
    </MdxLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      