import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import { useLocation } from '@reach/router';

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const location = useLocation();

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div><img src={location.origin + selectedValue} alt="Imagem Original" /></div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
        
 const GallerySection11 = () => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const data = useStaticQuery(graphql`
    query {
      gallery: allFile(filter: {sourceInstanceName: {eq: "gallery11"}, extension: {eq: "jpg"}}) {
        nodes {
          id
          name
          childImageSharp {
            original {
              src
            }
            gatsbyImageData(quality: 90, width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `)

  const handleClickOpen = (value) => {
    setSelectedValue(value.target.id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <div style={{textAlign: 'center', padding: '8px 0', marginBottom: '1.45rem'}}>
      <Grid container spacing={2}>
        {data.gallery.nodes.map(image => (
            <Grid item xs={6} sm={4} md={3} key={image.id}>
              <div style={{cursor: 'pointer'}} role="button" aria-label="Click" tabIndex={0} onKeyDown={handleClickOpen} onClick={handleClickOpen}>
              <GatsbyImage id={image.childImageSharp.original.src} alt="" image={image.childImageSharp.gatsbyImageData} />
              </div>
            </Grid>
          )
        )}  
      </Grid>  
        <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />  
    </div>
  );
}

export default GallerySection11